<template>
    <div>
      <div class="d-flex justify-content-end">
        <b-button
          @click="cleanUpFormChapter()"
          v-b-modal.form-chapter-modal
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary mb-2"
        >
          <!-- Add Modul <feather-icon icon="PlusIcon" size="16" /> -->
          Create Modul
        </b-button>
        <b-button
          @click="goBack()"
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary mb-2 ml-1"
        >
        <!-- <feather-icon icon="ArrowLeftIcon" size="14" />  -->
          Back
        </b-button>
      </div>
  
      <b-row>
        <b-col cols="6" v-for="(item, index) in chapter" :key="index">
          <b-card>
            <b-row>
                <b-col cols="6" sm="7" md="9">
                    <h3 class="">{{ item.title }} #{{ item.order }}</h3>
                </b-col>
                <b-col cols="6" sm="5" md="3" class="d-flex justify-content-end h-50">
                  <b-dropdown size="sm" variant="outline-primary">
                    <template #button-content> 
                      <feather-icon icon="Edit3Icon" size="14" /> 
                    </template>
                    <div>
                      <b-dropdown-item
                        @click="editChapter(item)"
                        v-b-modal.form-chapter-modal
                      >
                        <feather-icon icon="EditIcon" class="mr-75" />
                        Update
                      </b-dropdown-item>
                    </div>
                    <div>
                      <b-dropdown-item
                        @click="deleteChapter(item.uuid)"
                      >
                        <feather-icon icon="TrashIcon" class="mr-75" />
                          Delete
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </b-col>
            </b-row>
            <hr/>
            <div v-for="(content, index) in item.contents" :key="index">
                <b-row class="mb-1">
                  <b-col cols="2" sm="2" md="2" lg="1" align-self="center">
                    <div v-if="content.meta_file.type === 'document'"> 
                      <feather-icon icon="FileTextIcon" size="24" />
                    </div>
                    <div v-else-if="content.meta_file.type === 'video'"> 
                      <feather-icon icon="PlayCircleIcon" size="24" />
                    </div>
                    <div v-else> 
                      <feather-icon icon="ClipboardIcon" size="24" />
                    </div>
                  </b-col>
                  <b-col cols="5" sm="5" md="5" lg="6">
                      <div class="font-weight-bold">
                          {{ content.text }}
                      </div>
                      <div class="text-capitalize text-muted">
                          {{ content.meta_file.type }} 
                      </div>
                      <div v-if="content.type == 'post_test' || content.type == 'pre_test'" class="text-capitalize text-muted">
                        {{ content.type === 'post_test' ? 'Post Test' : 'Pre Test' }}
                      </div>
                  </b-col>
                  <b-col cols="5" sm="5" md="5" lg="3" class="text-center" align-self="center">
                    <div v-if="content.meta_file.type === 'document' && content.meta_file.page">
                      <div>
                        {{ content.meta_file.page }} Pages
                      </div>
                    </div>
                    <div v-else-if="content.meta_file.type === 'video' && content.meta_file.duration">
                      <div>
                        {{ content.meta_file.duration }} Seconds
                      </div>
                    </div>
                </b-col>
                  <b-col cols="12" md="12" lg="2" class="text-right h-50" align-self="center">
                    <b-dropdown size="sm" variant="outline-primary">
                      <template #button-content> 
                        <feather-icon icon="Edit3Icon" size="14" /> 
                      </template>
                      <div v-if="content.type !== 'default'">
                        <b-dropdown-item
                          @click="goQuestions(item, content)"
                        >
                          <feather-icon icon="ClipboardIcon" class="mr-75" />
                          Questions
                        </b-dropdown-item>
                      </div>
                      <div>
                        <b-dropdown-item
                          @click="editSubChapter(item, content)"
                        >
                          <feather-icon icon="EditIcon" class="mr-75" />
                          Update
                        </b-dropdown-item>
                      </div>
                      <div>
                        <b-dropdown-item
                          @click="deleteSubChapter(content.uuid)"
                        >
                          <feather-icon icon="TrashIcon" class="mr-75" />
                            Delete
                        </b-dropdown-item>
                      </div>
                    </b-dropdown>
                  </b-col>
                </b-row>
              </div>
              <b-button
                @click="modalCreateSubChapter(item)"
                variant="primary"
                class="btn waves-effect waves-float waves-light btn-primary mt-2"
              >
                Add Materi
            </b-button>
          </b-card>
        </b-col>
      </b-row>

      <!-- Modal Chapter -->
      <b-modal
        id="form-chapter-modal"
        centered
        :title="editId != null ? 'Edit E-Learning Category' : 'Add E-Learning Chapter'"
        size="lg"
        no-close-on-backdrop
        hide-footer
      >
        <ValidationObserver>
          <div class="form">
            <div class="form-group">
              <label>Title:</label>
              <validation-provider
                name="name"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  type="text"
                  class="form-control"
                  v-model="formPayloadChapter.title"
                  placeholder="Title"
                />
                <small
                  v-for="(validation, index) in validations.title"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label>Order:</label>
              <validation-provider
                name="name"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  type="number"
                  class="form-control"
                  v-model="formPayloadChapter.order"
                  placeholder="Order"
                />
                <small
                  v-for="(validation, index) in validations.order"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
          </div>
          <div v-if="isLoading">
            <br />
            <b-spinner class="mb-2" variant="primary" /><br />
          </div>
          <b-button
            v-if="editId == null"
            variant="primary"
            @click="createChapter()"
            v-bind:disabled="isLoading"
          >
            Save E-Learning Chapter
          </b-button>
          <b-button
            v-else
            variant="primary"
            @click="updateChapter()"
            v-bind:disabled="isLoading"
          >
            Edit E-Learning Chapter
          </b-button>
        </ValidationObserver>
      </b-modal>

      <!-- Modal Subchapter -->
      <b-modal
        id="form-sub-chapter-modal"
        centered
        :title="editId != null ? 'Edit E-Learning Subchapter' : 'Add E-Learning Subchapter'"
        size="lg"
        no-close-on-backdrop
        hide-footer
      >
        <ValidationObserver>
          <div class="form">
            <div class="form-group">
              <label>Title:</label>
              <validation-provider
                name="name"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  type="text"
                  class="form-control"
                  v-model="formPayloadSubChapter.text"
                  placeholder="Title"
                />
                <small
                  v-for="(validation, index) in validations.text"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label>Type:</label>
              <validation-provider
                name="name"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <v-select
                  id="class_type"
                  label="title"
                  v-model="formPayloadSubChapter.type"
                  :options="optionType"
                  placeholder="-- Pilih --"
                  :reduce="(option) => option.value"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
                <small
                  v-for="(validation, index) in validations.type"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label>Order:</label>
              <validation-provider
                name="name"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  type="number"
                  class="form-control"
                  v-model="formPayloadSubChapter.order"
                  placeholder="Order"
                />
                <small
                  v-for="(validation, index) in validations.order"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div v-if="!is_post_test && !is_pre_test">
              <div class="form-group">
                <label>Duration:</label>
                <validation-provider
                  name="name"
                  rules="required"
                  v-slot="{ errors, classes }"
                >
                  <input
                    type="number"
                    class="form-control"
                    v-model="formPayloadSubChapter.duration"
                    placeholder="Duration in seconds"
                  />
                  <small
                    v-for="(validation, index) in validations.duration"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  >
                </validation-provider>
              </div>
              <div class="form-group">
                <label>Page:</label>
                <validation-provider
                  name="name"
                  rules="required"
                  v-slot="{ errors, classes }"
                >
                  <input
                    type="number"
                    class="form-control"
                    v-model="formPayloadSubChapter.page_count"
                    placeholder="Page Count"
                  />
                  <small
                    v-for="(validation, index) in validations.page_count"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  >
                </validation-provider>
              </div>
              <div class="form-group">
                <label for="">Video / PDF:</label><br />
                <!-- <p class="image__hint"><i>Max 5 mb</i></p> -->
                <validation-provider name="image" rules="required">
                  <b-form-file
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    @change="changeFile($event)"
                  />
                  <small v-if="file_url !== null">Current file:</small><br />
                  <video
                    class="media_file"
                    controls
                    v-if="
                      (file_url !== null && file_type == 'video') ||
                      file_type == 'mp4' ||
                      file_type == 'flv' ||
                      file_type == 'mkv' ||
                      file_type == '3gp'
                    "
                  >
                    <source :src="file_url" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                    <!-- <iframe 
                      v-if="(file_url !== null && file_type == 'pdf')"
                      id="file"
                      class="media_file" 
                      type="application/pdf"
                      controls 
                      :src="file_url">
                    </iframe> -->
                    <a :href="file_url" v-if="(file_url !== null && file_type == 'pdf')">
                      <small>
                        Link PDF
                      </small>
                    </a>
                  <small class="text-danger">{{ validationsFile }}</small>
                </validation-provider>
              </div>
            </div>
          </div>
          <div v-if="isLoading">
            <br />
            <b-spinner class="mb-2" variant="primary" /><br />
          </div>
          <b-button
            v-if="editId == null"
            variant="primary"
            @click="createSubChapter()"
            v-bind:disabled="isLoading"
          >
            Save E-Learning Subchapter
          </b-button>
          <b-button
            v-else
            variant="primary"
            @click="updateSubChapter()"
            v-bind:disabled="isLoading"
          >
            Edit E-Learning Subchapter
          </b-button>
        </ValidationObserver>
      </b-modal>    
    </div>
</template>
  
<script>
  import { successNotification, errorNotification } from "@/auth/utils";
  import {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
    BSpinner,
    BFormFile,
    BDropdown,
    BDropdownItem,
    VBModal,
  } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  import _ from "lodash";
  import vSelect from "vue-select";
  import {
    ValidationProvider,
    ValidationObserver,
    configure,
  } from "vee-validate";
  import { required } from "@validations";
  configure({
    classes: {
      valid: "is-valid",
      invalid: "is-invalid",
      dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
      // ...
    },
  });
  export default {
    components: {
    //   Table,
      BCard,
      BCol,
      BRow,
      BButton,
      BSpinner,
      BFormInput,
      BFormFile,
      BDropdown,
      BDropdownItem,
      vSelect,
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      "b-modal": VBModal,
      Ripple,
    },
    watch: {
      filter: {
        handler: _.debounce(function() {
          this.getData();
        }, 300),
        deep: true,
      },
    },
    data() {
      return {
        required,
        currentPage: 1,
        isLoading: false,
        result: [],
        chapter: [],
        link: "",
        isLoadingExport: false,
        formPayloadChapter: {
          elearning_uuid: this.$route.params.uuid,
          title: "",
          order: null
        },
        formPayloadSubChapter: {
          elearning_uuid: this.$route.params.uuid,
          chapter_uuid: "",
          text: "",
          type: "default",
          file: null,
          order: null,
          duration: null,
          page_count: null
        },
        editId: null,
        createId: null,
        filter: {},
        validations: "",
        validationsFile: "",
        optionType: [
          { title: "Default", value: "default" },
          { title: "Pre Test", value: "pre_test" },
          { title: "Post Test", value: "post_test" },
        ],
        file_url: null,
        file_type: "",
        is_default: false,
        is_post_test: false,
        is_pre_test: false,
      };
    },
    setup() {
      return {
        successNotification,
        errorNotification,
      };
    },
    created() {
      this.getData();
    },
    watch: {
        'formPayloadSubChapter.type': function(newType) {
          this.is_default = newType === 'default';
          this.is_post_test = newType === 'post_test';
          this.is_pre_test = newType === 'pre_test';
        },
    },
    methods: {
      goBack() {
        this.cleanUpFormChapter();
        this.cleanUpFormSubChapter();
        this.$router.go(-1);
      },
      cleanUpFormChapter() {
        this.editId = null;
        this.formPayloadChapter = {
          elearning_uuid: this.$route.params.uuid,
          title: "",
          order: null
        };
      },
      editChapter(item) {
        this.cleanUpFormChapter();
        this.editId = item.uuid;
        this.formPayloadChapter = {
          elearning_uuid: this.$route.params.uuid,
          title: item.title,
          order: item.order,        
        };
        this.$bvModal.show("form-chapter-modal");
      },
      createChapter() {
        this.isLoading = true;
        this.validations = "";
        this.validationsFile = "";
        this.$http
          .post("/api/v1/admin/e-learning-content/chapter", this.formPayloadChapter)
          .then((response) => {
            this.$bvModal.hide("form-chapter-modal");
            this.getData(this.currentPage);
            successNotification(
              this,
              "Success",
              "E-Learning Chapter successfully created"
            );
            this.cleanUpFormChapter();
            this.isLoading = false;
          })
          .catch((error) => {
            if (error.response.data.meta.validations) {
              this.validations = error.response.data.meta.validations;
            }
            this.isLoading = false;
          });
      },
      updateChapter() {
        this.isLoading = true;
        this.$http
          .post(
            `/api/v1/admin/e-learning-content/chapter/${this.editId}`,
            this.formPayloadChapter
          )
          .then((response) => {
            this.$bvModal.hide("form-chapter-modal");
            this.getData();
            successNotification(
              this,
              "Success",
              "E-Learning Chapter successfully updated!"
            );
            this.cleanUpFormChapter();
            this.isLoading = false;
          })
          .catch((error) => {
            if (error.response.data.meta.validations) {
              this.validations = error.response.data.meta.validations;
            }
            this.isLoading = false;
          });
      },
      getData(page) {
        this.isLoading = true;
        this.currentPage = page;
        const queryParams = this.filter;
        queryParams.page = page;
        const elearning_uuid = this.$route.params.uuid;
        this.$http
          .get(`/api/v1/admin/e-learning-content/chapter?elearning_uuid=${elearning_uuid}`, {
            params: queryParams,
          })
          .then((response) => {
            this.chapter = response.data.data;
            // this.currentPage = response.data.data.meta.currentPage;
            this.isLoading = false;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      deleteChapter(slug) {
        this.$swal({
          title: "Are you sure?",
          text: "Are you sure to delete this E-Learning Chapter?",
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.$http
              .delete(`/api/v1/admin/e-learning-content/chapter/${slug}`)
              .then((response) => {
                this.getData();
                this.$swal({
                  icon: "success",
                  title: "Success!",
                  text: "E-Learning Chapter successfully deleted",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
                this.isLoading = false;
              })
              .catch((error) => {
                if (error.response.data.meta.messages.length > 0) {
                  errorNotification(
                    this,
                    "Oops!",
                    error.response.data.meta.messages
                  );
                }
              });
          }
        });
      },
      changeFile(event) {
        this.formPayloadSubChapter.file = event.target.files[0];
        this.showFile(event.target.files[0]);
      },
      showFile(file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.file_url = e.target.result;
          var str = e.target.result;
          var startIndex = str.indexOf("/") + 1; // Menentukan indeks mulai setelah karakter '/'
          var endIndex = str.indexOf(";"); // Menentukan indeks akhir sebelum karakter ';'
          var imageType = str.substring(startIndex, endIndex); // Memotong string berdasarkan indeks mulai dan akhir
          this.file_type = imageType;
        };
        reader.readAsDataURL(file);
      },
      cleanUpFormSubChapter() {
        this.editId = null;
        this.file_url = null;
        this.file_type = null;
        this.formPayloadSubChapter = {
          elearning_uuid: this.$route.params.uuid,
          title: "",
          text: "",
          type: "default",
          order: null,
          duration: null,
          page_count: null,
          file: null,
        };
      },
      editSubChapter(item, content) {
        this.cleanUpFormSubChapter();
        this.createId = item.uuid;
        this.editId = content.uuid;
        this.formPayloadSubChapter = {
          elearning_uuid: this.$route.params.uuid,
          text: content.text,
          type: content.type,        
          order: content.order,        
          duration: content.meta_file.duration,        
          page_count: content.meta_file.page,        
          file: content.meta_file.file,        
        };
        if(this.formPayloadSubChapter.type === 'default'){
          this.file_url = this.formPayloadSubChapter.file;
          this.file_type = this.file_url.substr(
            this.file_url.lastIndexOf(".") + 1
          );
          this.$bvModal.show("form-sub-chapter-modal");
        } else {
          this.$bvModal.show("form-sub-chapter-modal");
        }
      },
      goQuestions(item, content) {
        this.cleanUpFormSubChapter();
        this.createId = item.uuid;
        this.editId = content.uuid;
        this.formPayloadSubChapter = {
          elearning_uuid: this.$route.params.uuid,
          text: content.text,
          type: content.type,        
          order: content.order,        
          duration: content.meta_file.duration,        
          page_count: content.meta_file.page,        
          file: content.meta_file.file,        
        };
        this.$router.push('/e-learning-content/question/elearning_uuid=' + this.formPayloadSubChapter.elearning_uuid + '&content_uuid=' + this.editId)
      },
      modalCreateSubChapter(item){
        this.cleanUpFormSubChapter();
        this.createId = item.uuid;
        this.$bvModal.show("form-sub-chapter-modal");
      },
      createSubChapter() {
        this.isLoading = true;
        this.validations = "";
        const payload = new FormData();
        payload.append("elearning_uuid", this.formPayloadSubChapter.elearning_uuid);
        payload.append("chapter_uuid", this.createId);
        payload.append("text", this.formPayloadSubChapter.text);
        payload.append("type", this.formPayloadSubChapter.type);
        payload.append("file", this.formPayloadSubChapter.file);
        payload.append("order", this.formPayloadSubChapter.order);
        if (this.formPayloadSubChapter.duration !== null) {
          payload.append("duration", this.formPayloadSubChapter.duration);
        }
        if (this.formPayloadSubChapter.page_count !== null) {
          payload.append("page_count", this.formPayloadSubChapter.page_count);
        }
        this.$http
          .post("/api/v1/admin/e-learning-content/content", payload, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            this.$bvModal.hide("form-sub-chapter-modal");
            this.getData(this.currentPage);
            successNotification(
              this,
              "Success",
              "E-Learning Chapter successfully created"
            );
            this.cleanUpFormSubChapter();
            this.isLoading = false;
          })
          .catch((error) => {
            if (error.response.data.meta.validations) {
              this.validations = error.response.data.meta.validations;
            } else if (error.response.data.meta.messages) {
              this.validationsFile = error.response.data.meta.messages;
            }
            this.isLoading = false;
          });
      },
      updateSubChapter() {
        this.isLoading = true;
        this.validations = "";
        const payload = new FormData();
        payload.append("elearning_uuid", this.formPayloadSubChapter.elearning_uuid);
        payload.append("chapter_uuid", this.createId);
        payload.append("text", this.formPayloadSubChapter.text);
        payload.append("type", this.formPayloadSubChapter.type);
        payload.append("file", this.formPayloadSubChapter.file);
        payload.append("order", this.formPayloadSubChapter.order);
        if (this.formPayloadSubChapter.duration !== null ) {
          payload.append("duration", this.formPayloadSubChapter.duration);
        }
        if (this.formPayloadSubChapter.page_count !== null) {
          payload.append("page_count", this.formPayloadSubChapter.page_count);
        }
        this.$http
          .post(`/api/v1/admin/e-learning-content/content/${this.editId}`, payload, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            this.$bvModal.hide("form-sub-chapter-modal");
            this.getData();
            successNotification(
              this,
              "Success",
              "E-Learning Subchapter successfully updated!"
            );
            this.cleanUpFormSubChapter();
            this.isLoading = false;
          })
          .catch((error) => {
            if (error.response.data.meta.validations) {
              this.validations = error.response.data.meta.validations;
            } else if (error.response.data.meta.messages) {
              this.validationsFile = error.response.data.meta.messages;
            }
            this.isLoading = false;
          });
      },
      deleteSubChapter(slug) {
        this.$swal({
          title: "Are you sure?",
          text: "Are you sure to delete this E-Learning Subchapter?",
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.$http
              .delete(`/api/v1/admin/e-learning-content/content/${slug}`)
              .then((response) => {
                this.getData();
                this.$swal({
                  icon: "success",
                  title: "Success!",
                  text: "E-Learning Subchapter successfully deleted",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
                this.isLoading = false;
              })
              .catch((error) => {
                if (error.response.data.meta.messages.length > 0) {
                  errorNotification(
                    this,
                    "Oops!",
                    error.response.data.meta.messages
                  );
                }
              });
          }
        });
      },
    },
  };
</script>
  
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.px-25{
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.media_file {
  width: 100%;
  height: 300px;
  object-fit: contain;
}
</style>
  